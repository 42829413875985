<template>
  <div class="modal_founder">
    <div class="cont">  
      <img class="close" :src="require('@/assets/img/close.svg')" alt="close" @click="$emit('close')">
      <div class="cont_row d-flex align-items-center">
        <div class="cont_col w30">
          <img :src="founder.img" alt="" />
        </div>
        <div class="cont_col w70 p-3 wrapper">
          <h2 class="text=center">{{ $t(founder.name) }}</h2>
          <h3>{{ $t(founder.role) }}</h3>
          <div class="cont_row">
            <div class="cont_col w50 pr-3">
              <div class="education">
                <h4>{{ $t('education') }}</h4>
                <p class="break-spaces">{{ founder[`education_${language}`] }}</p>
                <!-- <p v-if="founder[`education_${language}_2`]">{{ founder[`education_${language}_2`] }}</p> -->
              </div>
              <div class="current_position">
                <h4>{{ $t('current_position') }}</h4>
                <p class="break-spaces">{{ founder[`current_position_${language}`] }}</p>
              </div>
              <div class="certificate">
                <h4 v-if="founder[`certificate_1_${language}`]" >{{ $t('certificate') }}</h4>
                <p class="break-spaces">{{ founder[`certificate_1_${language}`] }}</p>
              </div>
              <div class="publication" v-if="founder[`publication_1_${language}`]">
                <h4 v-if="founder[`publication_1_${language}`]">{{ $t('publication') }}</h4>
                <p class="break-spaces">{{ founder[`publication_1_${language}`] }}</p>
              </div>
            </div>
            <div class="cont_col w50">
                <!-- <div class="publication" v-if="founder.publication_1_en">
                    <h4 v-if="founder.publication_1_en">Publication</h4>
                    <p class="break-spaces">{{ founder.publication_1_en }}</p>
                </div> -->
              <div class="continuation">
                <p v-if="founder[`certificate_2_${language}`]" class="break-spaces">{{ founder[`certificate_2_${language}`] }}</p>
                <div class="publication_2">
                  <h4 v-if="founder[`certificate_2_${language}`]">{{ $t('publication') }}</h4>
                  <p v-if="founder[`publication_2_${language}`]" class="break-spaces">{{ founder[`publication_2_${language}`] }}</p>
                </div>
              </div>
            <div class="work">
                <h4 v-if="founder[`work_${language}`]" >{{ $t('work_experience') }}</h4>
                <p class="break-spaces">{{ founder[`work_${language}`] }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
    props: {
        founder: []
    },

    data () {
        return {}
    },

    computed: {
        ...mapFields(['language'])
    },

    mounted() {
        // console.log(this.founder)
    }
}
</script>

<style lang="scss" scope>
.modal_founder {
    font-size: 14px;
    background-color: #ffffff;

    h2 {
        font-size: 24px;
        color: #45b171;
        margin-bottom: .5rem;
    }

    h3 {
        font-size: 16px;
        margin-bottom: .5rem;
    }

    h4 {
        font-size: 14px;
        color: #45b171;
        margin-bottom: .5rem;
    }

    p {
        margin-bottom: 1rem;

        &.break-spaces {
            white-space: break-spaces;
            line-height: 1.5;
        }
    }

    .w30 {
        width: 30%;
    }

    .w70 {
        width: 70%;
    }

    .w50 {
        width: 50%;
    }

    img {
        width: 100%;
    }

    .cont {
        position: relative;

        .close {
            cursor: pointer;
            position: absolute;
            top: 20px;
            right: 20px;
            height: 18px;
            width: 18px;
        }
        
        &_row {
            overflow: hidden;
            display: flex;
            align-items: center;
        }
        &_col {
            float: left;
        }
    }

    .wrapper {
        padding: 0.5rem 1rem !important;
    }
}
.pr-3 {
    padding-right: 1rem;
    box-sizing: border-box;
}
</style>
